<template>
  <div id="property-cards-container py-4 text-[reset] font-[reset]">
    <Splide
      v-if="items?.length"
      :options="{
        focus: false,
        perMove: 3,
        drag: 'free',
        fixedWidth: '220px',
        gap: '16px',
        pagination: false,
        trimSpace: 'move',
        omitEnd: true,
        i18n: {
          slide: ''
        },
        breakpoints: {
          640: {
            arrows: false
          }
        }
      }"
      class="splide-center -mt-4 -mb-4 py-4"
    >
      <SplideSlide v-for="(item, index) in items" :key="index">
        <SitePropertyCard v-bind="item" class="my-2"></SitePropertyCard>
      </SplideSlide>
    </Splide>
  </div>
</template>

<script setup lang="ts">
import { Splide, SplideSlide } from '@splidejs/vue-splide';
import '@splidejs/vue-splide/css/core';

import type {
  PropertyCardProps,
  PropertyDocumentResponse,
  QueryResponse
} from '@/types/properties';

const SitePropertyCard = defineAsyncComponent(
  () => import('@/async-components/Site/PropertyCard.vue')
);

const props = defineProps({
  ids: {
    type: Array as PropType<string[]>,
    required: true
  },
  limit: {
    type: Number,
    default: 20
  }
});

const {
  public: { apiUrl }
} = useRuntimeConfig();

const { createSearchParams } = useSearch();

const { formatProperty } = useProperties();

const { get } = useHttpMethods();

async function fetch(): Promise<PropertyCardProps[]> {
  const queryParams = createSearchParams({
    perPage: props.limit,
    filterBy: [`id:[${props.ids.join(',')}]`],
    includeFields:
      'bathsFull,bathsHalf,bedrooms,city,county,full,geopoint,id,listPrice,mlsId,photos,postalCode,propertyArea,state,streetName,subType,type'
  });

  const url = apiUrl + '/search/?' + new URLSearchParams(queryParams);

  const response = await get<{ data: QueryResponse<PropertyDocumentResponse> }>(
    url
  );

  if (!response.data) {
    throw new Error('No data');
  }

  return response.data.hits.map((property, index) => {
    return { ...formatProperty(property.document, index), hideCarousel: true };
  });
}

const items = ref<PropertyCardProps[]>([]);

onMounted(() => {
  fetch().then(response => (items.value = response));
});
</script>

<style lang="scss">
@import '~/assets/css/splide-theme.scss';
</style>
